$theme1: #0063A9; //Sportkompas
$theme1-light: #D7E5F3; //Sportkompas Light
$theme2: #48C0EF; //Zwemmen
$theme2-light: #48C0EF; //Zwemmen Light
$theme3: #B2BE25; //Sportservice
$theme3-light: #B2BE25; //Sportservice Light
$theme4: #006B6E; //Green
$theme4-light: #006B6E; //Green Light
$theme5: #DE2424; //Agenda & Nieuws
$theme5-light: #DE2424; //Agenda & Nieuws Light
$theme6: #f5a800; //Huren Yellow
$theme6-light: #fac25e; //Huren Yellow Light
$light-gray: #f1f1f1; //Light gray theme support color
$primary: $theme1 !default;
$secondary: $theme2 !default;

$border-width: 1px;
$input-border-color: gray;
$theme-colors: (
        'theme1': $theme1,
        'theme2': $theme2,
        'theme3': $theme3,
        'theme4': $theme4,
        'theme5': $theme5,
        'theme6': $theme6,
        'theme1-light': $theme1-light,
        'theme2-light': $theme2-light,
        'theme3-light': $theme3-light,
        'theme4-light': $theme4-light,
        'theme5-light': $theme5-light,
        'theme6-light': $theme6-light,
        'light-gray': $light-gray
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$input-btn-border-width: 2px;
$btn-border-width: $input-btn-border-width;

$border-radius: 0;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
* {
  font-family: Ubuntu, serif;
}
@import '../../../../src/scss/main.scss';

h1, h2, .h1, .h2 {
  color: $theme1;
}

.card {
  border: 0;
}

#topNav {
  .social-icons {
    a {
      color: white;
    }
  }
  .nav-link {
    @extend .btn;
    @extend .ml-2;
    @extend .btn-light-gray;
  }
}

.bullet-img {
  @extend .ml-3;
  @extend .mb-3;
  left: 0;
  bottom: 0;
  width: 150px;
}

.content-wrapper {
  @extend .mx-md-5;
}

.calendar-wrapper {
  input {
    text-align: left !important;
  }
}

.bullet-img-cta {
  @extend .ml-3;
  @extend .mb-0;
  left: 0;
  bottom: 0;
  width: 150px;
  @include media-breakpoint-down(md) {
    width: 85px;
  }
}

.lead {
  font-size: 1rem;
}

#Rooster, #rooster {
  @extend .mt-3;
  table {
    .thead-light {
      border-bottom: solid 3px $theme1;
      th {
        border: none;
        background-color: white !important;
        color: $theme1 !important;
      }
    }
    a {
      text-decoration: underline;
    }
  }
  h3 {
    font-size: 1.25rem;
  }
  select, input {
    color: $theme1;
  }
  button[type=submit] {
    background-color: $theme1;
    color: white !important;
    &:hover {
      background: darken($theme1, 0.5) !important;
      border-color: darken($theme1, 0.5) !important;
    }
  }
}

.part-category-nav {
  .list-group-item {
    a {
      color: black;
      font-weight: 300;
    }
  }
}

.top-bar--item {
  list-style: none;
  @extend .ml-2;
  @extend .py-2;
  a {
    color: white;
    &:hover {
      color: $theme2;
    }
  }
}

.flavourImg {
  margin-top: -50px;
}

.header-image {
  margin-top: -8px;
  z-index: 99;
}

.activity-tickets-widget {
  h3 {
    @extend h2;
    @extend .my-4
  }
}

.agenda-split--left {
  @extend .col-lg-8;
  .card {
    overflow: visible !important;
    @extend .mb-4;
    .card-title {
      font-weight: bold;
    }
  }
  h5 {
    color: $theme1;
  }
  .agenda-split--line-break {
    @extend .mt-4;
  }
}

.breadcrumb--bar {
  background-color: $light-gray;
  border-bottom: solid 2px $theme2;
}

.account-button--style {
  @extend .btn-light-gray;
}

.social-icons {
  @extend .h-100;
  @extend .pt-1;
  @extend .px-2;
  font-size: 1.5em;
}

.flavourImg {
  right: 0;
  img {
    opacity: 10%;
    height: 450px;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.page-link {
  border-color: $theme1;
}

.part--card {
  background-color: white !important;
  border: none;
  .card-body {
    @extend .p-0;
    .card-title {
      a {
        color: black !important;
      }
      @extend .mt-3;
      color: black !important;
    }
    .card-subtitle {
      color: $theme1 !important;
    }
    .btn {
      @extend .btn-block;
    }
    .fa-fw {
      display: none;
    }
  }
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
}

.navbar {
  padding: 0 8px;
  border-bottom: solid 8px $theme2;
  .navbar-brand {
    padding-top: 0;
    img {
      width: 250px;
    }
    @include media-breakpoint-down(sm) {
      img {
        width: 200px;
      }
    }
  }
  li.nav-item {
    .text-white {
      color: white !important;
    }
    margin-bottom: 6px;
    @extend .pr-0;
    @extend .btn;
    @extend .pl-2;
    @extend .m-0;
    a {
      padding: 16px 0px 10px 0px;
      color: black !important;
    }
  }
}

main {
  min-height: 55vh;
}

.h1--banner {
  text-shadow: 0px 3px 2px rgba(0,0,0,0.2);
}

.banner--publish {
  @extend .py-3;
  box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.1);
}

.watermark {
  opacity: 0.8 !important;
}

.text-white {
  color: white !important;
}

.img-cover {
  min-height: 350px;
  background-position: center;
  background-size: cover;
  border-bottom: solid 8px $theme2;
  h1 {
    color: white;
  }
  div {
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    width: 100%;
  }
}

.img-cover--subhome {
  aspect-ratio: 16/10;
  background-position: center;
  background-size: cover;
  border: solid 10px $theme1-light;
}

.subhome {
  h1 {
    color: white !important;
    @include media-breakpoint-down(sm) {
      color: $theme1 !important;
    }
  }
}

.flavor-shape {
  z-index: -1;
  width: 100%;
  height: 350px;
  @include media-breakpoint-down(md) {
    height: 250px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }

}

.agenda-split--right {
  @extend .mb-5;
}

.subhome--summary {
  @extend .text-white;
  @include media-breakpoint-down(sm) {
    color: $theme1 !important;
    margin-top: 10px;
  }
}

.min-height {
  // iets
}

.dropdown-menu {
  border-top: solid $btn-border-width $theme1;
  left: 16px;
  top: 85%;
  .dropdown-item:active {
    color: white !important;
  }
  .nav-btn-minimal {
    display: contents !important;
  }
}

.nav-link {
  @extend .py-2;
  @extend .px-3;
}

.imageCover {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.hover-overlay--banner {
  hr, .btn {
    transition: transform .3s;
    transform-style: flat;
  }
  &:hover {
    hr {
      transform: scaleX(1.2);
    }
    .btn {
      @extend .btn-theme2;
    }
  }
}

.btn-theme2, .btn-theme4 {
  color: white;
}

.accordion button::after {
  color: $primary;
}

.img--side {
  z-index: -1 !important;
}

.banner-section {
  p {
    line-height: 2em;
  }
  .banner-overlay, .banner-overlay-small{
    h2{
      color: white;
    }
    hr {
      border: solid 2px;
      box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.1);
      width: 50%;
    }
    background-color: rgba(0,0,0,0.25);
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .text-center;
  }
  .banner-overlay {
    height: 100%;
    @include media-breakpoint-down(md) {
      min-height: 250px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 150px;
    }
  }
  .banner-overlay-small {
    @include media-breakpoint-down(md) {
      min-height: 150px;
    }
  }
}

.menu-section {
  .banner-overlay, .banner-overlay-small{
    h2{
      color: white;
      font-size: 1.6em;
    }
    background-color: rgba(0,0,0,0.25);
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .text-center;
    @include media-breakpoint-up(md) {
      aspect-ratio: 1/1;
    }
  }
  hr {
    border: solid 2px;
  }
}


//agenda

.card-stretch-hover {
  .card--img-top {
    width: 100%;
    transition: transform .3s;
  }
  &:hover {
    .card--img-top {
      transform: scale(1.1);
    }
  }
}

.animated-link {
  color: black !important;
  i {
    @extend .ml-1;
    transition: transform .2s;
  }
  &:hover {
    i {
      transform: translateX(6px);
    }
  }
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}

.content-wrapper {
  @extend .mt-5;
}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

.badge-wrapper {
  width: 50%;
  right: 25%;
  margin-top: -20px;
  @include media-breakpoint-down(md) {
    margin-top: -10px;
    width: 80%;
    right: 10%;
  }
}

.agenda-split--left {
  .card {
    overflow: visible !important;
  }
}

.img-wrapper {
  .date-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: solid 9px;
    @extend .p-2, .mr-4, .bg-white;
  }
}

//agenda end

.agenda-split {
  .far {
    font-size: 1.5em;
  }
  .pb-3 {
    @extend .pb-4;
  }
  .agenda-split--right {
    @extend .pl-lg-5, .col-lg-4;
  }
  .card-body {
    background-color: white;
    @extend .pt-3;
    .card-title {
      @extend .mb-1;
    }
  }
  .card {
    background-color: #ffffff;
  }
  h6 {
    @extend h4;
    color: $theme1;
  }
  .date-badge--string {
    @extend .text-theme1;
  }
}

.color-to-theme > div:first-child, .color-to-theme > div:nth-child(2) {
  @extend .pb-3;
}

.color-to-theme > div:nth-child(4) {
  @extend .pt-3;
  @extend .pt-sm-0;
}

.card {
  background-color: $light-gray;
}

footer {
  background-position: center;
  background-size: cover;
  .bg-footer {
    background: linear-gradient(to bottom,  rgba(0,99,169,1) 6%,rgba(0,99,169,0.6) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0063a9', endColorstr='#990063a9',GradientType=0 ); /* IE6-9 */
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
.btn-light-gray:hover {
  @extend .btn-theme2;
}

.home, .subhome {
  margin-top: -8px;
  z-index: 6;
}

.planner-home {
  @include media-breakpoint-down(xs) {
    .form-group {
      display: flex;
      input {
        margin-right: 1rem;
      }
      label {
        display: none;
      }
    }
  }
}

h2, .planner-home, form, .text-md-center {
  text-align: unset;
  @extend .text-left;
  justify-content: left !important;
}

.card-stretch-hover {
  .card-img-top {
    width: 100%;
    transition: transform .3s;
    @extend .h-auto;
  }
  &:hover {
    .card-img-top {
      transform: scale(1.1);
    }
  }
}
